import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ps_nav_top = _resolveComponent("ps-nav-top");
  const _component_router_view = _resolveComponent("router-view");
  const _component_ps_nav_bottom = _resolveComponent("ps-nav-bottom");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_ps_nav_top), _createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component,
      route
    }) => [_createVNode(_Transition, {
      name: "fade",
      mode: "out-in",
      appear: true
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
        include: ['AboutView', 'NewsView', 'CultureView']
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: route.name
      }))], 1024))]),
      _: 2
    }, 1024)]),
    _: 1
  }), _createVNode(_component_ps_nav_bottom)], 64);
}