export const getImage = (src) => {

  return `${process.env.VUE_APP_IMAGE_URL}${src}`;

}

export const getApiImage = (src) => {

  return `${process.env.VUE_APP_API_URL}/file${src}`;

}

export const htmlGetPlainText = (richStr) => { // 提取富文本文字内容
  let value = richStr;
  if (value) {
    // 方法一： 
    // value= value.replace(/\s*/g, "");  //去掉空格
    value= value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
    value = value.replace(/↵/g, "");     //去掉所有的↵符号
    value = value.replace(/[\r\n]/g, ""); //去掉回车换行
    value = value.replace(/&nbsp;/g, ""); //去掉空格
    const arrEntities = {'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
    value = value.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];}); //转意符换成普通字符
    return value;
  } else {
    return '';
  }
}